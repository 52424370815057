<template>
  <v-card class="mx-auto" elevation="0">
    <v-card-title class="card-header fixed-card-title">
      {{$t('add-patient')}}
      <v-spacer></v-spacer>
      <v-icon class="white-color" @click="closeDrawer">mdi-close</v-icon>
    </v-card-title>
    <v-card-text class="px-2 py-16">
      <v-form
          ref="form"
          v-model="valid"
          lazy-validation
      >
        <v-container>
          <v-row dense>
            <v-col cols="6">
              <v-text-field
                  outlined
                  hide-details
                  dense
                  v-model="userDetails.firstName"
                  :rules="[v => !!v || 'Item is required']"
                  :label="$t('name')"
                  required
                  :key="componentKey"
              ></v-text-field>
            </v-col>
            <v-col cols="6">
              <v-text-field
                  outlined
                  :rules="[v => !!v || 'Item is required']"
                  hide-details
                  dense
                  v-model="userDetails.lastName"
                  :label="$t('surname')"
                  required
                  :key="componentKey"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row dense>
            <v-col cols="6">
              <v-select
                  outlined
                  hide-details
                  dense
                  :label="$t('gender')"
                  :items="patients.gender"
                  v-model="userDetails.gender"
                  :key="componentKey"
                  :menu-props="{ offsetY: true }"
              >
                <template v-slot:item="{ item }">
                  {{ item.text }}
                </template>
              </v-select>
            </v-col>
            <v-col cols="6">
              <v-text-field
                  ref="phoneNumber"
                  outlined
                  hide-details
                  dense
                  type="phone"
                  v-model="phone"
                  :label="$t('phone')"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row dense>
            <v-col cols="12">
              <validation-provider
                  ref="email"
                  :name="$t('email')"
                  rules="required|email"
                  v-slot="{ errors }"
              >
                <v-text-field
                    :label="$t('email')"
                    v-model="userDetails.email"
                    dense
                    type="email"
                    :rules="[v => !!v || 'Item is required']"
                    hide-details
                    outlined
                    :error-messages="(emailError = errors)"
                    :key="componentKey"
                    :disabled="isGenericEmailAccount"
                >
                  <template v-slot:append>
                    <v-row dense class="mt-0">
                      <v-tooltip top color="primary" style="z-index:9999;">
                        <template v-slot:activator="{ on }">
                          <v-simple-checkbox
                              color="primary"
                              dense
                              class="align-center"
                              v-model="isGenericEmailAccount"
                              @click="handleGenericEmailAccountCheckbox"
                              v-on="on"
                          ></v-simple-checkbox>
                        </template>
                        <span>{{$t('generic-email-account-label')}}</span>
                      </v-tooltip>
                    </v-row>
                  </template>
                </v-text-field>
              </validation-provider>
            </v-col>
          </v-row>

          <v-row dense>
            <v-col cols="6">
              <v-text-field
                  outlined
                  hide-details
                  dense
                  v-model="userDetailsPolicyNumber"
                  :label="$t('policyNumber')"
                  :key="componentKey"
              ></v-text-field>
            </v-col>
            <v-col cols="6">
              <v-select
                  outlined
                  :menu-props="{ bottom: true, offsetY: true }"
                  :rules="[v => !!v || 'Item is required']"
                  dense
                  hide-details
                  :error="checkSelectedInsurance"
                  @change="isInsuranceSelected"
                  item-value="lowValue"
                  :items= "healthInsurancePrograms"
                  :item-text= "domain=> domain.shortDescription"
                  v-model="healthInsuranceProgramID"
                  :label="$t('insurance')"
                  :key="componentKey"
              >
              </v-select>
            </v-col>
          </v-row>
          <v-row dense>
            <v-col
                cols="6"
            >
              <validation-provider
                  :name="$t('height')"
                  v-slot="{ errors }">
                <v-text-field
                  :label="userSettings.Units === 'METRIC' ? $t('height-in-cm') : $t('height-in-ft')"
                  v-model="heightInCm"
                  outlined
                  type="number"
                  dense
                  hide-details
                  :error-messages="errors"
                ></v-text-field>
              </validation-provider>
            </v-col>
            <v-col
                cols="6"
            >
              <validation-provider
                  :name="$t('weight')"
                  v-slot="{ errors }">
              <v-text-field
                  :label="userSettings.Units === 'METRIC' ? $t('weight-in-kg') : $t('weight-in-lbs')"
                  v-model="weightInKg"
                  outlined
                  dense
                  type="number"
                  hide-details
                  :error-messages="errors"
              ></v-text-field>
              </validation-provider>
            </v-col>
          </v-row>
          <v-row
              dense
          >
            <v-col cols="6">
            <BirthdatePicker :inputValue.sync="birthdate" />
            </v-col>
            <v-col cols="6">
              <v-text-field
                  outlined
                  hide-details
                  dense
                  v-model="userDetails.mrn"
                  :label="$t('mrn')"
              ></v-text-field>
            </v-col>

          </v-row>
          <v-row dense>
            <v-col cols="12">
              <v-text-field
                  :readonly="useSameAddressLikeHospital"
                  ref="address"
                  @blur="handleLostFocus"
                  v-model="patientAddress"
                  :label="$t('address')"
                  outlined
                  hide-details
                  dense
                  :rules="[v => !!v || 'Item is required']"
                  :key="componentKey"
              >
                <template v-slot:append>
                  <v-row dense class="mt-0">
                    <v-tooltip top color="primary" style="z-index:9999;">
                      <template v-slot:activator="{ on }">
                        <v-simple-checkbox
                            color="primary"
                            dense
                            class="align-center"
                            v-model="useSameAddressLikeHospital"
                            @click="handleCheckboxAddressSelectedFromOffice()"
                            v-on="on"
                        ></v-simple-checkbox>
                      </template>
                      <span>{{$t('addressForSameHospital')}}</span>
                    </v-tooltip>
                  </v-row>
                </template>
              </v-text-field>
            </v-col>
<!--            <v-col cols="1" class="d-flex align-center">-->
<!--              <v-tooltip left>-->
<!--                <template v-slot:activator="{ on }">-->
<!--                  <v-simple-checkbox-->
<!--                      color="primary"-->
<!--                      dense-->
<!--                      class="align-center"-->
<!--                      v-model="useSameAddressLikeHospital"-->
<!--                      @click="handleCheckboxAddressSelectedFromOffice"-->
<!--                      v-on="on"-->
<!--                  ></v-simple-checkbox>-->
<!--                </template>-->
<!--                <span>{{$t('addressForSameHospital')}}</span>-->
<!--              </v-tooltip>-->
<!--            </v-col>-->
          </v-row>
          <v-row dense>
            <v-col class="pa-1" cols="6">
              <v-text-field
                  :readonly="useSameAddressLikeHospital"
                  outlined
                  @blur="handleLostFocus"
                  hide-details
                  dense
                  :rules="[v => !!v || 'Item is required']"
                  :label="$t('city')"
                  v-model="addressInfo.city"
                  required
                  :key="componentKey"
              ></v-text-field>
            </v-col>
            <v-col class="pa-1" cols="6">
              <v-text-field
                  :readonly="useSameAddressLikeHospital"
                  outlined
                  hide-details
                  @blur="handleLostFocus"
                  dense
                  :rules="[v => !!v || 'Item is required']"
                  :label="$t('state')"
                  v-model="addressInfo.state"
                  required
                  :key="componentKey"
              ></v-text-field>
            </v-col>
            <v-col class="pa-1" cols="6">
              <v-text-field
                  :readonly="useSameAddressLikeHospital"
                  outlined
                  hide-details
                  @blur="handleLostFocus"
                  dense
                  :rules="[v => !!v || 'Item is required']"
                  :label="$t('zip')"
                  v-model="addressInfo.zip"
                  required
                  :key="componentKey"
              ></v-text-field>
            </v-col>
            <v-col class="pa-1" cols="6">
              <v-text-field
                  :readonly="useSameAddressLikeHospital"
                  outlined
                  @blur="handleLostFocus"
                  hide-details
                  dense
                  :rules="[v => !!v || 'Item is required']"
                  :label="$t('country')"
                  v-model="addressInfo.country"
                  required
                  :key="componentKey"
              ></v-text-field>

            </v-col>
          </v-row>
          <v-row  v-if="!addressIsValid" dense>
            <v-col cols="12">
              <v-combobox
                  :label="$t('time-zone')"
                  dense
                  hide-details="auto"
                  outlined
                  :rules="[v => !!v || $t('timeZoneSelect')]"
                  v-model="timezone"
                  item-text="timeZoneId"
                  item-value="timeZoneId"
                  :items="getTimeZone"
                  required
                  :key="componentKey"
              ></v-combobox>
            </v-col>
          </v-row>
          <v-row class="mb-3 px-2">
            <v-col
                class="d-flex pa-1"
                cols="6"
                sm="6"
            >
              <div class="d-flex flex-column">
                <span class="font-weight-bold color-black">{{$t('enable-rpm')}}</span>
                <v-btn-toggle
                    v-model="enableRPM"
                    row
                    active-class="chips white--text"
                    mandatory
                    class="white"
                >
                  <v-btn
                      class="tabs-class"
                      :value="1"
                      :input-value="enableRPM"
                  >{{$t("yes")}}</v-btn>
                  <v-btn
                      class="tabs-class"
                      :value="0"
                      :input-value="enableRPM"

                  >{{$t("no")}}</v-btn>
                </v-btn-toggle>
              </div>
            </v-col>
<!--            <v-col-->
<!--                class="d-flex pa-1"-->
<!--                cols="6"-->
<!--                sm="6"-->
<!--            >-->
<!--              <div class="d-flex flex-column">-->
<!--                <span class="font-weight-bold color-black">{{$t('enable-rtm')}}</span>-->
<!--                <v-btn-toggle-->
<!--                    v-model="enableRTM"-->
<!--                    row-->
<!--                    active-class="chips white&#45;&#45;text"-->
<!--                    mandatory-->
<!--                    class="white"-->
<!--                >-->
<!--                  <v-btn-->
<!--                      class="tabs-class"-->
<!--                      :value="1"-->
<!--                      :input-value="enableRTM"-->
<!--                  >{{$t("yes")}}</v-btn>-->
<!--                  <v-btn-->
<!--                      class="tabs-class"-->
<!--                      :value="0"-->
<!--                      :input-value="enableRTM"-->
<!--                  >{{$t("no")}}</v-btn>-->
<!--                </v-btn-toggle>-->

<!--              </div>-->
<!--            </v-col>-->
          </v-row>
          <v-row class="justify-space-between my-2 mx-0" dense>
            <v-btn-toggle
                v-model="smoker"
                row
                active-class="chips white--text"
                mandatory
                class="white"
 >
              <v-btn
                  class="tabs-class"
                  value='0'
                  >{{$t("non-smoker")}}</v-btn>
              <v-btn
                  class="tabs-class"
                  value='1'
              >{{$t("smoker")}}</v-btn>
            </v-btn-toggle>
            <v-btn-toggle
                row
                active-class="chips white--text"
                mandatory
                v-model="glucoseAndCholesterolUnit"
                class="white"
            >
              <v-btn
                  class="tabs-class"
                  value='mg/dl'
                  @click="setGlucoseAndCholesterolUnit('mg/dl')"
              >MG/DL</v-btn>
              <v-btn
                  class="tabs-class"
                  value='mmol/l'
                  @click="setGlucoseAndCholesterolUnit('mmol/l')"
              >MMOL/L</v-btn>
            </v-btn-toggle>
          </v-row>
          <v-row dense class="d-flex justify-space-between my-2 mx-0">
            <v-btn-toggle
                v-model="userDetails.language"
                row
                active-class="chips white--text"
                mandatory
                class="white"
            >
              <v-btn
                  class="tabs-class"
                  value='ENG'
              >{{$t("english")}}</v-btn>
              <v-btn
                  class="tabs-class"
                  value='ESP'
              >{{$t("spanish")}}</v-btn>
            </v-btn-toggle>
            <v-btn-toggle
                row
                mandatory
                v-model="scaleAndTempUnit"
                class="white"
                active-class="chips white--text"
            >
              <v-btn
                  class="tabs-class"
                  value='IMPERIAL'
                  @click="setImperialOrMetric('IMPERIAL')"
              >{{$t("imperial")}}</v-btn>
              <v-btn
                  class="tabs-class"
                  value='METRIC'
                  @click="setImperialOrMetric('METRIC')"
              >{{$t("metric")}}</v-btn>
            </v-btn-toggle>
          </v-row>
          <v-row dense class="mx-0">
            <v-tabs
                :show-arrows="true"
                v-model="tab"
                background-color="secondary"
                grow
                hide-slider
                dense
                height="30px"
                centered icons-and-text
                active-class="chips white--text ma-0"
            >
              <v-tab v-for="(tab, index) in tabsHeader" :key="index">{{ tab.title }}</v-tab>
              <v-tab-item v-for="(tab, index) in tabsHeader" :key="index">

                <v-textarea
                    v-if="tab.title !=='Diagnoses'"
                    v-model="tab.content"
                    :label="tab.title"
                    solo
                    outlined
                    auto-grow
                    hide-details
                    dense
                    class="mt-2"
                    no-resize
                >
                </v-textarea>
                <div v-if="tab.title==='Diagnoses'" class="margin-top20">
                  <v-autocomplete
                      @input="handleAutoCompleteInput"
                      v-model="selectedVerifier"
                      :items="icd10Codes"
                      :item-text="$i18n.locale === 'en' ? 'shortDescription' : 'shortDescriptionEsp'"
                      item-value="lowValue"
                      hide-details
                      outlined
                      dense
                      :label="$t('main-category-of-diagnosis')"
                      :menu-props="{ maxWidth: '450'}"
                  >
                  </v-autocomplete>
                  <v-row dense>
                    <v-col  cols="12">
                      <v-data-table
                          class="ma-0 pa-0"
                          dense
                          height="300px"
                          :headers="headers"
                          fixed-header
                          :no-data-text="$t('no-data-available')"
                          :items="filteredCategory"
                          :items-per-page="-1"
                          hide-default-footer
                          :loading="loadingData"
                      >
                        <template v-slot:item="{ item }">
                          <tr @click="selectedDiagnose(item)">
                            <td>
                              <v-checkbox
                                  hide-details
                                  :value="item"
                                  class="mt-n1"
                              >
                              </v-checkbox></td>
                            <td class="text-left font-bold"><span>{{ item.icd10Code }}</span></td>
                            <td class="text-left"><span>{{ item.fullDescription }}</span></td>
                          </tr>
                        </template>
                      </v-data-table>
                    </v-col>
                  </v-row>
                </div>
              </v-tab-item>
            </v-tabs>
          </v-row>
          <v-row dense class="mx-0 pt-1">
            <v-data-table
                :height="dataTableHeight"
                :headers="headers2"
                :items="getDoctorAndNurse"
                fixed-header
                class="full-width"
                hide-default-footer
                :items-per-page="-1"
                dense
            >
              <template v-slot:item="{item}">
                <tr>
                  <td class="text-left font-size13 font-weight-bold" >{{item.firstName}} {{item.lastName}}</td>
                  <td>
                    <v-checkbox
                        :disabled="item.id === primaryDoctorSelected ? true : false"
                        class="pl-8 ma-0 mb-1"
                        hide-details
                        color="primary"
                        :value="item.id"
                        v-model="staffSelected"
                    >
                    </v-checkbox>
                  </td>
                  <td>
                    <v-radio-group class="pa-0 ma-0" hide-details v-model="primaryDoctorSelected">
                      <v-radio
                          color="primary"
                          v-if="item.roleId === 3"
                          :key="item.id"
                          :value="item.id"
                      >
                      </v-radio>
                    </v-radio-group>
                  </td>
                </tr>
              </template>
            </v-data-table>
          </v-row>
        </v-container>
      </v-form>
    </v-card-text>

    <v-card-actions class="fixed-card-actions">
      <v-row dense class="pa-0">
        <v-col cols="6">
          <v-btn
              @click="closeDrawer"
              elevation="0"
              class="cancel-button-style capitalize-text"
          >
            {{$t('cancel')}}
          </v-btn>
        </v-col>
        <v-col cols="6">
          <v-btn
              @click="loader = 'loading'"
              :disabled="isSaveButtonDisabled"
              class="save-button-style"
          >
            {{$t('save')}}
          </v-btn>
        </v-col>
      </v-row>
    </v-card-actions>
  </v-card>
</template>

<script>
import { mapGetters, mapState } from 'vuex';
import { AsYouType, isValidPhoneNumber } from 'libphonenumber-js';
import BirthdatePicker from '@/views/Client/components/BirthdatePicker.vue';
// import { fromTo } from '@/utils/unit-converter';
import { showErrorAlert, showSuccessAlert } from '@/utils/utilities';
import { fromTo } from '@/utils/unit-converter';

export default {
	name: 'DashboardCoreAppBar',
	components: {
		BirthdatePicker,
	},
	data: () => ({
    addressIsValid: true,
    useSameAddressLikeHospital: true,
    selectedDiagnoses: [],
    isGenericEmailAccount: false,
    search: '',
    checkSelectedInsurance: true,
    loadingData: false,
    category: [],
    selectedVerifier: '',
    componentKey: 0,
    healthInsuranceProgramID: null,
		userDetails: {
			firstName: '',
			lastName: '',
			email: '',
		},
		dataStatus: null,
		loader: null,
		loading: null,
		primaryDoctorSelected: [],
		staffSelected: [],
		patientAddress: '',
		validationErrors: [],
		addressInfo: {
			address: '',
			zip: '',
			city: '',
			state: '',
			country: '',
			lat: 32.798718741846955,
			lng: -96.79917112574036,
		},
		smoker: '0',
		heightInCm: null,
		birthdate: '2000-01-01',
		weightInKg: null,
		tab: null,
		userDetailsPolicyNumber: '',
		emailError: false,
		scaleAndTempUnit: 'IMPERIAL',
		glucoseAndCholesterolUnit: 'mg/dl',
		patients: {
			gender: [{ text: 'Male', value: 1 }, { text: 'Female', value: 2 }],
		},
		phone: '+1',
		timezone: null,
		formattedAddress: null,
		valid: true,
		phoneErrors: false,
    icd10Codes: [],
    healthInsurancePrograms: [],
    enableRTM: 0,
    enableRPM: 0,
	}),
	computed: {
		...mapState({
			userData: (state) => state.authentication.userData,
      detailedUser: (state) => state.users.detailedUser,
      detailsDrawer: (state) => state.detailsDrawer,
		}),
		...mapGetters({
      roleName: 'authentication/getRole',
			hospitalId: 'authentication/getHospitalId',
			userSettings: 'authentication/getUserSettings',
			getDoctorAndNurse: 'hospitals/getDoctorAndNurse',
			getTimeZone: 'verifiers/getTimeZone',
		}),
    dataTableHeight () {
      if (this.$vuetify.breakpoint.width <= 1200 && this.$vuetify.breakpoint.height <= 802) {
        return 200;
      } else if (this.$vuetify.breakpoint.width <= 2560 && this.$vuetify.breakpoint.height <= 1305) {
        return 465;
      } else {
        return 200;
      }
    },
    filteredCategory () {
      if (!this.search) {
        return this.category; // Return all items if search is empty
      }
      const keyword = this.search.toLowerCase();
      return this.category.filter(item => {
        return (
            item.icd10Code.toLowerCase().includes(keyword) ||
            item.fullDescription.toLowerCase().includes(keyword)
        );
      });
    },
		genderValue () {
			if (this.userDetails.gender === 1) {
				return 'M';
			} else if (this.userDetails.gender === 2) {
				return 'F';
			}
			return null;
		},
    isSaveButtonDisabled () {
      return (
      !this.userDetails.gender ||
      !this.userDetails.firstName ||
      !this.timezone ||
      !this.userDetails.lastName ||
      !this.userDetails.email ||
      !this.patientAddress ||
      !this.addressInfo.zip ||
      !this.addressInfo.city ||
      !this.addressInfo.state ||
      !this.addressInfo.country ||
      !this.birthdate ||
      this.primaryDoctorSelected.length === 0 ||
      !this.staffSelected ||
      this.healthInsuranceProgramID === null
      );
    },
		headers () {
			const headers = [
        { text: '', sortable: false },
        { text: this.$t('name'), sortable: false, align: 'start' },
        { text: this.$t('description'), sortable: false, align: 'start' },
			];
			return headers;
		},
    headers2 () {
      const headersMedicalTeam = [
        { text: this.$t('name'), sortable: false, align: 'start' },
        { text: '', sortable: false, align: 'start' },
        { text: '', sortable: false, align: 'start' },
      ];
      return headersMedicalTeam;
    },
		tabsHeader () {
			return [
				{ title: this.$t('treatments'), content: '' },
				{ title: this.$t('allergies'), content: '' },
        { title: this.$t('diagnoses'), content: '' },
        // { title: this.$t('problem-list'), content: '' },
			];
		},
	},
  async mounted () {
    await this.initializeAddPatient();
  },
	methods: {
    async initializeAddPatient () {
      this.checkAndFixLocationDetails();

      await this.getStaff();

      await this.handleGenericEmailAccountCheckbox();
      if (!isValidPhoneNumber(this.phone)) {
        this.phoneErrors = true;
      }
      await this.getVerifiersForDomain();
      this.checkValid();
      this.dataStatus = this?.userData?.patientMetadata?.isManualMeasurement;
    },
    checkAndFixLocationDetails () {
      this.addressInfo.address = this.userData.hospitalAssignedData.address;

      this.patientAddress = this.userData.hospitalAssignedData.address;
      this.addressInfo.city = this.userData.hospitalAssignedData.city;
      this.addressInfo.state = this.userData.hospitalAssignedData.state;
      this.addressInfo.zip = this.userData.hospitalAssignedData.zip;
      this.addressInfo.country = this.userData.hospitalAssignedData.country;
      if (this.userData.hospitalAssignedData.addressLatitude !== 0.01 && this.userData.hospitalAssignedData.addressLongitude !== 0.01 && this.userData.hospitalAssignedData.addressLongitude !== null && this.userData.hospitalAssignedData.addressLatitude !== null) {
        // TODO there is good address call  edpoint for geting the correct time zone with given lan lng

        const latLng = {
          lat: this.userData.hospitalAssignedData.addressLatitude,
          lng: this.userData.hospitalAssignedData.addressLongitude,
        };

        this.$store.dispatch(
            'googleAPI/getTimeZone',
            latLng,
        ).then(response => {
          this.timezone = response.zoneName;
          this.addressInfo.lat = this.userData.hospitalAssignedData.addressLatitude;
          this.addressInfo.lng = this.userData.hospitalAssignedData.addressLongitude;
          this.addressIsValid = true;
        });
      } else {
        // TODO There is bad address show time zoene lookup
        this.addressInfo.lat = 0.01;
        this.addressInfo.lng = 0.01;
        this.addressIsValid = false;
      }
    },
    isInsuranceSelected () {
      if (this.healthInsuranceProgramID === null) {
       this.checkSelectedInsurance = true;
      } else {
        this.checkSelectedInsurance = false;
      }
    },
    resetComponent () {
      this.userDetails.firstName = '';
      this.userDetails.lastName = '';
      this.userDetails.email = '';
      this.userDetails.gender = '';
      this.userDetailsPolicyNumber = '';
      this.healthInsuranceProgramID = null;
      this.checkSelectedInsurance = true;
      this.tabsHeader.find(tab => tab.title === this.$t('treatments')).content = '';
      this.tabsHeader.find(tab => tab.title === this.$t('allergies')).content = '';
      this.componentKey += 1;
      this.category = [];
      this.selectedVerifier = '';
      this.timezone = '';
    },
    async insertPatientDiagnose (selectedICD10Code, selectedFullDescription) {
      const body = {
        patientUserId: this.detailedUser.userData.id,
        staffId: this.userData.id,
        hospitalId: this.userData.hospitalAssignedData.id,
        diagnosisCode: selectedICD10Code,
        diagnoseDescription: selectedFullDescription,
      };
      const response = await this.$store.dispatch('patientdiagnosis/createPatientDiagnosis', body);
      if (response.resFlag) {
        showSuccessAlert(this.$t('icdCodeAdded'));

        this.detailedUser.userData.patientDiagnosisList.push(response.data);
      } else {
        showErrorAlert(this.$t('icdCodeError'));
      }
    },
    async getDiagnosesByCategory (item) {
      const response = await this.$store.dispatch('patientmetadata/getDiagnosesByCategory', item);
      if (response && response?.data) {
        this.category = response?.data;
      }
    },
    async getVerifiersForDomain () {
      // optimise this by calling the getVerifiersForMultipleDomainsV2
      const header = { domainsList: 'HEALTH_INSURANCE_PROGRAM,ICD_10_MAIN_CODES' };
      var response = await this.$store.dispatch('verifiers/getVerifiersForMultipleDomainsV2', header);
      if (response !== null) {
        this.healthInsurancePrograms = response != null ? response?.filter(item => item.rvDomain === 'HEALTH_INSURANCE_PROGRAM') : [];
        this.icd10Codes = response != null ? response?.filter(item => item.rvDomain === 'ICD_10_MAIN_CODES') : [];
      }

      await this.$store.dispatch('verifiers/getTimeZones');
    },
    async selectedDiagnose (item) {
      if (this.selectedDiagnoses.includes(item)) {
        // remove in this case
        for (let i = 0; i < this.selectedDiagnoses.length; i++) {
          if (this.selectedDiagnoses[i].icd10Code === item.icd10Code) {
            this.selectedDiagnoses.splice(i, 1);
            break;
          }
        }
      } else {
        // add to array
        this.selectedDiagnoses.push(item);
      }
    },
		async handleAutoCompleteInput () {
      if (!this.selectedVerifier) {
        return;
      }
      try {
        this.loadingData = true;
        const body = this.selectedVerifier.toString();
        const response = await this.$store?.dispatch('patientmetadata/getDiagnosesByCategory', body);
        if (response && response.data) {
          this.category = response?.data;
          this.loadingData = false;
        }
      } catch (error) {
        // console.log(error);
      }
    },
    async handleGenericEmailAccountCheckbox () {
      if (this.isGenericEmailAccount === true) {
        this.userDetails.email = 'generic@wellnessmetric.net'; // checked
      } else {
        this.userDetails.email = ''; // not checked
      }
    },
    async handleCheckboxAddressSelectedFromOffice () {
      if (this.useSameAddressLikeHospital) {
        this.checkAndFixLocationDetails();
      } else {
          this.addressIsValid = false;
          this.patientAddress = '';
          this.addressInfo.zip = '';
          this.addressInfo.city = '';
          this.addressInfo.state = '';
          this.addressInfo.country = '';
          this.addressInfo.lat = 0.01;
          this.addressInfo.lng = 0.01;
      }
    },
		async manageMedicalTeam (res) {
			const body = {
				hospitalId: this.userData.hospitalAssignedData.id,
				patientId: res.id,
				primaryDoctorId: this.primaryDoctorSelected,
				medicalTeamTitle: res.firstName + ' ' + res.lastName + ' Team',
				medicalTeamDescription: res.firstName + ' ' + res.lastName,
				status: 1,
			};
			await this.$store
				.dispatch('medicalteam/createMedicalTeam', body)
				.then((res) => {
					const createdMedicalTeamId = res.id;
					this.assignMemberToMedicalTeam(createdMedicalTeamId);
				});
		},
		async assignMemberToMedicalTeam (medicalTeamId) {
			const bodyAssign = {
				medicalTeamId: medicalTeamId,
				memberUserIdList: this.staffSelected,
			};
			await this.$store.dispatch('medicalteam/assignMultipleMembersToMedicalTeam', bodyAssign);
			this.$emit('false');
		},
		handleLostFocus () {
      if (!this.useSameAddressLikeHospital) {
        this.addressIsValid = false;
        if (!this.addressIsValid) {
          if (this.patientAddress !== '' && this.addressInfo.city !== '' && this.addressInfo.zip !== '' && this.addressInfo.state !== '' && this.addressInfo.country !== '') {
            this.userDetails.address = this.patientAddress + ', ' + this.addressInfo.city + ', ' + this.addressInfo.state + ' ' + this.addressInfo.zip + ', ' + this.addressInfo.country;
            this.locationDetails();
          }
        } else {
          this.userDetails.address = this.userData.hospitalAssignedData.address;
          this.locationDetails();
        }
      }
		},
		setImperialOrMetric (unit) {
			this.scaleAndTempUnit = unit;
			this.getPreferences();
		},
		setGlucoseAndCholesterolUnit (unit) {
			this.glucoseAndCholesterolUnit = unit;
			this.getPreferences();
		},
		getPreferences () {
			const preferences = {
				Units: this.scaleAndTempUnit,
				Concentration: this.glucoseAndCholesterolUnit,
			};
			return preferences;
		},
		checkValid () {
			this.validationErrors = [];
			if (this.phoneErrors) {
				this.validationErrors.push('phone');
			}
			if (this.emailError.length > 0) {
				this.validationErrors.push('email');
			}
			if (this.addressInfo.address === '') {
				this.validationErrors.push('address');
			}
			if (this.birthdate === '') {
				this.validationErrors.push('birthdate');
			}
			if (this.validationErrors.length > 0) {
				return false;
			} else return true;
		},
		async createPatient () {
			const bodyForPatientCreate = {
				firstName: this.userDetails.firstName,
				lastName: this.userDetails.lastName,
				phone: this.phone.split(' ').join(''),
				email: this.userDetails.email,
				address: this.addressInfo.address,
				zip: this.addressInfo.zip,
				city: this.addressInfo.city,
				state: this.addressInfo.state,
				country: this.addressInfo.country,
				addressLatitude: this.addressInfo.lat,
				addressLongitude: this.addressInfo.lng,
				// MentalHealth: false,
				// MentalHealth: this.userDetails.mentalHealth,
				username: this.userDetails.email,
				gender: this.genderValue,
				birthdate: this.birthdate,
				roleId: 5, // PATIENT
				language: this.userDetails.language,
        userTimezone: this.addressIsValid ? this.timezone : this.timezone.timeZoneId,
        userSettingsJson: JSON.stringify(this.getPreferences()),
				IsManualMeasurement: this.dataStatus,
				hospitalId: this.userData.hospitalAssignedData.id,
        isEmailAccountProvided: !this.isGenericEmailAccount,
        isRpmEnabled: this.enableRPM === 1,
        // isRtmEnabled: this.enableRTM === 1,
      };

			await this.$store.dispatch('users/createUser', bodyForPatientCreate).then(async (res) => {
        // FD: i commented this, because i dont see why we need
        // this.userData = res;

        if (res.resFlag === true) {
          // handle patient metadata
					const metaDataBody = {
						userId: res.data.id,
						heightInCm: this.userSettings.Units === 'METRIC' ? parseInt(this.heightInCm) : parseFloat(fromTo(this.heightInCm, 'ft-us', 'cm').toFixed(2)),
						weightInKg: this.userSettings.Units === 'METRIC' ? parseInt(this.weightInKg) : parseFloat(fromTo(this.weightInKg, 'lb', 'kg').toFixed(2)),
						ssn: this.ssn,
						medicalRegistrationNumber: this.userDetails.mrn,
						patientIdentifier: this.patientIdentifier,
						healthInsurance: this.healthInsuranceProgramID,
						memberIdPolicyNumber: this.userDetailsPolicyNumber,
						isCigaretteSmoker: this.smoker === '1',
						alcoolConsumtion: this.userDetails.alcoolConsumtion,
						prescriptions: this.tabsHeader.find(tab => tab.title === this.$t('treatments')).content,
						// mentalHealth: false,
						// mentalHealth: this.userDetails.mentalHealth,
						metadataInfo: this.tabsHeader.find(tab => tab.title === this.$t('allergies')).content,
						IsManualMeasurement: this.dataStatus,
					};
					await this.$store.dispatch('patientmetadata/editPatientMetaData', metaDataBody);

          // handle medical team
          await this.manageMedicalTeam(res.data);

          // handle patient diagnosis
          for (let i = 0; i < this.selectedDiagnoses.length; i++) {
            const body = {
              patientUserId: res.data.id,
              staffId: this.userData.id,
              hospitalId: this.userData.hospitalAssignedData.id,
              diagnosisCode: this.selectedDiagnoses[i].icd10Code,
              diagnoseDescription: this.selectedDiagnoses[i].fullDescription,
            };
            await this.$store.dispatch('patientdiagnosis/createPatientDiagnosis', body);
          }

          const requestSetStatus = {
            userId: res.data.id,
            statusValueToSet: res.data.status,
          };
          await this.$store.dispatch('users/setUserStatus', requestSetStatus);

          showSuccessAlert(this.$t('successfully-created-patient'));

					await this.getPatients(); // refresh the patients list
          this.resetComponent();
					this.closeDrawer();
				} else {
					showErrorAlert(res.msg);
				}
			});
		},
		async locationDetails () {
			if (this.userDetails.address) {
				const geoLocation = await this.$store.dispatch(
					'googleAPI/getGeoLocation',
					this.userDetails.address,
				);

				if (geoLocation.address === undefined || geoLocation.address == null) {
					this.addressInfo = {
						address: this.userDetails.address,
						zip: this.addressInfo.zip,
						formattedAddress: this.userDetails.address,
						city: this.addressInfo.city,
						state: this.addressInfo.state,
						country: this.addressInfo.country,
						lat: 0.01,
						lng: 0.01,
					};
          this.addressIsValid = false;
					return;
				}

				const addressComponents = geoLocation.address;
				this.addressInfo.lat = (geoLocation.lat === undefined || geoLocation.lat === null) ? 0.01 : geoLocation.lat;
				this.addressInfo.lng = (geoLocation.lon === undefined || geoLocation.lon === null) ? 0.01 : geoLocation.lon;
				this.formattedAddress = (geoLocation.display_name === undefined || geoLocation.display_name === null) ? this.userDetails.address : geoLocation.display_name;
				this.addressInfo.address = this.formattedAddress;
				this.addressInfo.city = addressComponents.city || addressComponents.village || addressComponents.suburb || 'N/A';
				this.addressInfo.state = addressComponents.state || 'N/A';
				this.addressInfo.country = addressComponents.country || 'N/A';
				this.addressInfo.zip = addressComponents.postcode || 'N/A';

				if (this.addressInfo.lat !== null && this.addressInfo.lng !== null) {
					const timeZoneResponse = await this.timeZone(this.addressInfo.lat, this.addressInfo.lng);
					if (timeZoneResponse) {
						this.timezone = timeZoneResponse.zoneName;
            this.addressIsValid = true;
					}
				}
			} else {
        this.addressIsValid = false;
      }
		},
		async timeZone (lat, lng) {
			const object = {
				lat: lat,
				lng: lng,
			};
      return await this.$store.dispatch('googleAPI/getTimeZone', object);
		},
		closeDrawer () {
			this.userDetails.firstName = '';
			this.userDetails.lastName = '';
			this.userDetails.mrn = '';
			this.userDetails.email = '';
			this.phone = '+1';
			this.formattedAddress = null;
			this.addressInfo.lat = null;
			this.addressInfo.lng = null;
			this.smoker = '0';
			this.glucoseAndCholesterolUnit = 'mg/dl';
			this.scaleAndTempUnit = 'IMPERIAL';
			this.userDetails.language = 'eng';
			this.heightInCm = null;
			this.birthdate = '2000-01-01';
			this.weightInKg = null;
			this.tab = null;
			this.valid = false;
			this.fileUpload = null;
			this.primaryDoctorSelected = [];
			this.staffSelected = [];
      this.isGenericEmailAccount = false;
      this.useSameAddressLikeHospital = true;
      this.selectedDiagnoses = [];

			this.$store.commit('SET_DETAIL_DRAWER_STATE', false);
			this.$store.commit('SET_DETAILS_DRAWER', false);
      this.resetComponent();
		},
		async getStaff () {
			await this.$store.dispatch('hospitals/getRelatedUsersForHospital', this.userData.hospitalAssignedData.id);
		},
		async getPatients () {
			const hospitalIdFromStore = this.userData.hospitalAssignedData.id;
			if (this.roleName === 'HOSPITAL_ADMIN' || this.roleName === 'DOCTOR' || this.roleName === 'NURSE') {
				await this.$store.dispatch('hospitals/getRelatedPatientsV3', hospitalIdFromStore)
					.then(() => {
						// this.filteredUsers = this.relatedPatients;
						// this.filteredUsers?.forEach((user) => {
						// 	user.fullname = user.firstName + ' ' + user.lastName;
						// 	user.age = this.calculateAge(user.birthdate);
						// 	user.sex = user.gender === 'M' ? this.$t('male') : this.$t('female');
						// 	user.phone = new AsYouType().input(user.phone);
						// 	if (user.hasMedicalTeam === true) {
						// 		user.medTeam = 1;
						// 	}
						// 	user.patientHasDoneReadingForTodayValue = user.patientHasDoneReadingForToday === true ? this.$t('yes') : this.$t('no');
						// 	this.verifiersFromRepo.forEach(v => {
						// 		if (user.status === v.lowValue) {
						// 			user.statusShortDescription = v.shortDescription;
						// 			user.statusLongDescription = v.longDescription;
						// 		}
						// 	});
						// });
						// this.loading = false;
					});
			} else if (this.roleName === 'ORGANIZATION_ADMIN') {
				// this.filteredUsers = [];
				// this.loading = false;
			} else {
				// this.filteredUsers = [];
				// this.loading = false;
			}
		},
	},
	watch: {
    detailsDrawer: {
      deep: true,
      handler (newVal) {
        if (newVal) {
          this.initializeAddPatient();
          // detailsDrawer is true
        } else {
          this.resetComponent();
        }
      },
    },
		primaryDoctorSelected (val) {
			// if (!this.staffSelected.includes(val)) {
      if (Number.isInteger(val) && !this.staffSelected.includes(val)) {
				this.staffSelected.push(val);
			}
		},
		async loader () {
			if (this.loader !== null) {
				const l = this.loader;
				this[l] = !this[l];

				await this.createPatient()
					.then(() => {
						this[l] = false;
					})
					.catch(() => {
						this[l] = false;
					});

				this.loader = null;
			}
		},
		phone (val) {
			if (val !== undefined) {
				if (!isValidPhoneNumber(val)) {
					this.phoneErrors = true;
				} else {
					this.phoneErrors = false;
				}
				this.phone = new AsYouType().input(val);
			}
		},
	},
};
</script>
